import {command, fetchAndMergeResult, fetchAndSetResult, generateGetterById, uploadFile} from '@/stores/api';
import {useAuthStore} from '@/stores/auth';
import type {
  MySettlement,
  MySettlements,
  MyWageBonus,
  MyWageBonuses,
  MyWageProfile,
  MyWageProfiles,
} from '@/stores/generated/accounting';
import {type EmployeeParticipation, useCourseStore} from '@/stores/generated/course';
import type {Characteristics, PersonnelData} from '@/stores/generated/employee';
import type {Commitment, Commitments, MyEvent, MyEvents, MyShift, MyShifts} from '@/stores/generated/event';
import {useLocationStore} from '@/stores/generated/location';
import type {Payslips} from '@/stores/generated/payslip';
import type {MyTask, MyTasks} from '@/stores/generated/task';
import {filterObject, findObject, groupObject, sortObject} from '@/util/object';
import {until} from '@vueuse/core';
import {defineStore, storeToRefs} from 'pinia';
import {computed, shallowRef} from 'vue';

export const useUserStore = defineStore('user', () => {
  const authStore = useAuthStore();
  const locationStore = useLocationStore();
  const courseStore = useCourseStore();

  const courseStoreState = storeToRefs(courseStore);

  const events = shallowRef<MyEvents>({});
  const shifts = shallowRef<MyShifts>({});
  const commitments = shallowRef<Commitments>({});
  const tasks = shallowRef<MyTasks>({});
  const settlements = shallowRef<MySettlements>({});
  const wageProfiles = shallowRef<MyWageProfiles>({});
  const wageBonuses = shallowRef<MyWageBonuses>({});
  const payslips = shallowRef<Payslips>({});
  const characteristics = shallowRef<Characteristics>({});
  const personnelData = shallowRef<PersonnelData>({
    employee_id: '',
    full_name: '',
    email_address: null,
    birthday: null,
    place_of_residence: null,
    avatar: null,
    characteristics: [],
    properties: {},
    update_requests: {},
  });

  const employeeId = computed(() => authStore.userProfile.employee_id);
  const requestedEvents: string[] = [];
  const requestedShiftsFromEvent: string[] = [];
  const requestedWageProfiles: string[] = [];
  const requestedWageBonuses: string[] = [];

  const getEventById = generateGetterById<MyEvent>(events);
  const getShiftById = generateGetterById<MyShift>(shifts);
  const getCommitmentById = generateGetterById<Commitment>(commitments);
  const getTaskById = generateGetterById<MyTask>(tasks);
  const getSettlementById = generateGetterById<MySettlement>(settlements);
  const getWageProfileById = generateGetterById<MyWageProfile>(wageProfiles);
  const getWageBonusById = generateGetterById<MyWageBonus>(wageBonuses);

  const getPayslipWithMonth = computed(
    () => (month: string) => findObject(payslips.value, (payslip) => payslip.month === month),
  );

  const requests = computed(() => filterObject(commitments.value, (commitment) => 'requested' === commitment.status));

  const requestsByEvent = computed(() => groupObject(requests.value, 'event_id'));

  const courseParticipations = computed(() =>
    filterObject(
      courseStoreState.employeeParticipations,
      (employeeParticipation: EmployeeParticipation) => employeeParticipation.employee_id === employeeId.value,
    ),
  );
  const courseRequests = computed(() =>
    filterObject(
      courseParticipations.value,
      (employeeParticipation: EmployeeParticipation) => 'requested' === employeeParticipation.status,
    ),
  );

  const settlementsSorted = computed(() =>
    sortObject(settlements.value, (left, right) => right.month.localeCompare(left.month)),
  );

  const settlementsByMonth = computed(() => groupObject(settlementsSorted.value, 'month'));

  const characteristicsSorted = computed(() =>
    sortObject(characteristics.value, (left, right) =>
      left.characteristic_label.localeCompare(right.characteristic_label),
    ),
  );

  const characteristicsByType = computed(() => groupObject(characteristicsSorted.value, 'characteristic_type'));

  const fetchMyLocations = async (filter: {} = {}) => {
    await until(employeeId).toBeTruthy();
    await locationStore.fetchMyLocations({
      ...filter,
      employee_id: employeeId.value,
    });
  };

  const fetchMyEvent = async (filter: FindMyEvent) => {
    await until(employeeId).toBeTruthy();

    if (requestedEvents.includes(filter.event_id)) {
      return;
    }

    requestedEvents.push(filter.event_id);

    return fetchAndMergeResult('event.find-my-event', events, {
      employee_id: employeeId.value,
      event_id: filter.event_id,
    });
  };

  const fetchMyShiftsForEvent = async (filter: FindMyShiftsForEvent) => {
    await until(employeeId).toBeTruthy();

    if (requestedShiftsFromEvent.includes(filter.event_id)) {
      return;
    }

    requestedShiftsFromEvent.push(filter.event_id);

    return fetchAndMergeResult('event.find-my-shifts-for-event', shifts, {
      employee_id: employeeId.value,
      event_id: filter.event_id,
    });
  };

  const fetchMyUpcomingCommitments = async () => {
    await until(employeeId).toBeTruthy();

    return fetchAndSetResult('event.find-my-upcoming-commitments', commitments, {
      employee_id: employeeId.value,
    });
  };

  const fetchMyUpcomingCourseParticipations = async () => {
    await until(employeeId).toBeTruthy();

    return fetchAndSetResult(
      'course.find-upcoming-participations-for-employee',
      courseStoreState.employeeParticipations,
      {
        employee_id: employeeId.value,
      },
    );
  };

  const fetchMyCourse = async (filter: {course_id: string}) => {
    await until(employeeId).toBeTruthy();
    await courseStore.fetchCourse({
      ...filter,
      employee_id: employeeId.value,
    });
  };

  const fetchMyCourseModules = async (filter: {course_id: string}) => {
    await until(employeeId).toBeTruthy();
    await courseStore.fetchCourseModules({
      ...filter,
      employee_id: employeeId.value,
    });
  };

  const fetchMyTasks = async () => {
    await until(employeeId).toBeTruthy();

    return fetchAndSetResult('task.find-my-tasks', tasks, {
      employee_id: employeeId.value,
    });
  };

  const fetchMySettlements = async () => {
    await until(employeeId).toBeTruthy();

    return fetchAndSetResult('accounting.find-my-settlements', settlements, {
      employee_id: employeeId.value,
    });
  };

  const fetchMyWageProfile = async (filter: FindMyWageProfile) => {
    await until(employeeId).toBeTruthy();

    if (requestedWageProfiles.includes(filter.wage_profile_id)) {
      return;
    }

    requestedWageProfiles.push(filter.wage_profile_id);

    return fetchAndMergeResult('accounting.find-my-wage-profile', wageProfiles, {
      employee_id: employeeId.value,
      ...filter,
    });
  };

  const fetchMyWageBonus = async (filter: FindMyWageBonus) => {
    await until(employeeId).toBeTruthy();

    if (requestedWageBonuses.includes(filter.wage_bonus_id)) {
      return;
    }

    requestedWageBonuses.push(filter.wage_bonus_id);

    return fetchAndMergeResult('accounting.find-my-wage-bonus', wageBonuses, {
      employee_id: employeeId.value,
      ...filter,
    });
  };

  const fetchMyPayslips = async () => {
    await until(employeeId).toBeTruthy();

    return fetchAndSetResult('payslip.find-my-payslips', payslips, {
      employee_id: employeeId.value,
    });
  };

  const fetchMyPersonnelData = async () => {
    await until(employeeId).toBeTruthy();

    return fetchAndSetResult('employee.find-my-personnel-data', personnelData, {
      employee_id: employeeId.value,
    });
  };

  const fetchMyCharacteristics = async () => {
    await until(employeeId).toBeTruthy();

    return fetchAndSetResult('employee.find-characteristics', characteristics, {
      employee_id: employeeId.value,
    });
  };

  const acceptMyShifts = async (data: AcceptMyShiftsCommandData) => {
    await until(employeeId).toBeTruthy();

    await Promise.all(
      data.commitment_ids.map((commitmentId: string) => {
        return command('event.accept-shift', {
          employee_id: employeeId.value,
          commitment_id: commitmentId,
        });
      }),
    );

    await fetchMyUpcomingCommitments();
  };

  const declineMyShifts = async (data: DeclineMyShiftsCommandData) => {
    await until(employeeId).toBeTruthy();

    await Promise.all(
      data.commitment_ids.map((commitmentId: string) => {
        return command('event.decline-shift', {
          employee_id: employeeId.value,
          commitment_id: commitmentId,
        });
      }),
    );

    await fetchMyUpcomingCommitments();
  };

  const confirmMyShift = async (data: ConfirmMyShiftCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('event.confirm-shift', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyUpcomingCommitments();
  };

  const requestIdentityCardNumberUpdate = async (data: RequestIdentityCardNumberUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-identity-card-number-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestPersonalTaxNumberUpdate = async (data: RequestPersonalTaxNumberUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-personal-tax-number-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestRegistrationAddressUpdate = async (data: RequestRegistrationAddressUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-registration-address-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestBankAccountUpdate = async (data: RequestBankAccountUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-bank-account-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestPayrollDataUpdate = async (data: RequestPayrollDataUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-payroll-data-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestHealthInsuranceUpdate = async (data: RequestHealthInsuranceUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-health-insurance-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestGenderUpdate = async (data: RequestGenderUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-gender-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestPensionInsuranceExemptionUpdate = async (data: requestPensionInsuranceExemptionUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-pension-insurance-exemption-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const setEmergencyContact = async (data: SetEmergencyContactCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.set-emergency-contact', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const requestFurtherEmploymentsUpdate = async (data: RequestFurtherEmploymentsUpdateCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.request-further-employments-update', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const setContact = async (data: SetContactCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.set-contact', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const setCharacteristics = async (data: SetCharacteristicsCommandData) => {
    await until(employeeId).toBeTruthy();

    await command('employee.set-characteristics', {
      employee_id: employeeId.value,
      ...data,
    });

    await fetchMyPersonnelData();
  };

  const uploadProfileImage = async (data: UploadProfileImageData) => {
    await until(employeeId).toBeTruthy();
    await uploadFile('profile_image', data.file);
    await fetchMyPersonnelData();
  };

  return {
    employeeId,
    events,
    shifts,
    commitments,
    courseParticipations,
    courseRequests,
    tasks,
    settlements,
    wageProfiles,
    wageBonuses,
    payslips,
    characteristics,
    personnelData,
    getEventById,
    getShiftById,
    getCommitmentById,
    getTaskById,
    getSettlementById,
    getWageProfileById,
    getWageBonusById,
    getPayslipWithMonth,
    requestsByEvent,
    settlementsByMonth,
    characteristicsByType,
    fetchMyLocations,
    fetchMyEvent,
    fetchMyShiftsForEvent,
    fetchMyUpcomingCommitments,
    fetchMyUpcomingCourseParticipations,
    fetchMyCourse,
    fetchMyCourseModules,
    fetchMyTasks,
    fetchMySettlements,
    fetchMyWageProfile,
    fetchMyWageBonus,
    fetchMyPayslips,
    fetchMyPersonnelData,
    fetchMyCharacteristics,
    acceptMyShifts,
    declineMyShifts,
    confirmMyShift,
    requestIdentityCardNumberUpdate,
    requestPersonalTaxNumberUpdate,
    requestRegistrationAddressUpdate,
    requestBankAccountUpdate,
    requestPayrollDataUpdate,
    requestHealthInsuranceUpdate,
    requestGenderUpdate,
    requestPensionInsuranceExemptionUpdate,
    setEmergencyContact,
    setContact,
    setCharacteristics,
    requestFurtherEmploymentsUpdate,
    uploadProfileImage,
  };
});

interface FindMyEvent {
  event_id: string;
}

interface FindMyShiftsForEvent {
  event_id: string;
}

interface FindMyWageProfile {
  wage_profile_id: string;
}

interface FindMyWageBonus {
  wage_bonus_id: string;
}

interface AcceptMyShiftsCommandData {
  commitment_ids: string[];
}

interface DeclineMyShiftsCommandData {
  commitment_ids: string[];
}

interface ConfirmMyShiftCommandData {
  commitment_id: string;
}

interface RequestIdentityCardNumberUpdateCommandData {
  identity_card_number: string;
}

interface RequestPersonalTaxNumberUpdateCommandData {
  personal_tax_number: string;
}

interface RequestRegistrationAddressUpdateCommandData {
  registration_address: object;
}

interface RequestBankAccountUpdateCommandData {
  bank_account: object;
}

interface RequestPayrollDataUpdateCommandData {
  payroll_data: object;
}

interface RequestHealthInsuranceUpdateCommandData {
  health_insurance: object;
}

interface RequestGenderUpdateCommandData {
  gender: string;
}

interface requestPensionInsuranceExemptionUpdateCommandData {
  pension_insurance_exemption: boolean;
}

interface SetEmergencyContactCommandData {
  emergency_contact: object;
}

interface RequestFurtherEmploymentsUpdateCommandData {
  further_employments: object;
}

interface SetContactCommandData {
  contact: object;
}

interface SetCharacteristicsCommandData {
  characteristics: object;
}

interface UploadProfileImageData {
  file: File;
}
