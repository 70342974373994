/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useEventStore = defineStore('event', () => {
  const cashRegisterCards = shallowRef<CashRegisterCards>({});
  const coWorkerCosts = shallowRef<CoWorkerCosts>({});
  const employees = shallowRef<Employees>({});
  const coWorkers = shallowRef<CoWorkers>({});
  const commitmentEfforts = shallowRef<CommitmentEfforts>({});
  const commitmentStatusUpdates = shallowRef<CommitmentStatusUpdates>({});
  const commitments = shallowRef<Commitments>({});
  const employeeCommitments = shallowRef<EmployeeCommitments>({});
  const employeeCosts = shallowRef<EmployeeCosts>({});
  const eventCategories = shallowRef<EventCategories>({});
  const events = shallowRef<Events>({});
  const kioskOperations = shallowRef<KioskOperations>({});
  const shifts = shallowRef<Shifts>({});
  const tenantWorkerCosts = shallowRef<TenantWorkerCosts>({});
  const tenantWorkers = shallowRef<TenantWorkers>({});
  const workerAttendances = shallowRef<WorkerAttendances>({});
  const myEvents = shallowRef<MyEvents>({});
  const myShifts = shallowRef<MyShifts>({});

  const getCashRegisterCardById = generateGetterById<CashRegisterCard>(cashRegisterCards);
  const getCoWorkerCostById = generateGetterById<CoWorkerCost>(coWorkerCosts);
  const getEmployeeById = generateGetterById<Employee>(employees);
  const getCoWorkerById = generateGetterById<CoWorker>(coWorkers);
  const getCommitmentEffortById = generateGetterById<CommitmentEffort>(commitmentEfforts);
  const getCommitmentStatusUpdateById = generateGetterById<CommitmentStatusUpdate>(commitmentStatusUpdates);
  const getCommitmentById = generateGetterById<Commitment>(commitments);
  const getEmployeeCommitmentById = generateGetterById<EmployeeCommitment>(employeeCommitments);
  const getEmployeeCostById = generateGetterById<EmployeeCost>(employeeCosts);
  const getEventCategoryById = generateGetterById<EventCategory>(eventCategories);
  const getEventById = generateGetterById<Event>(events);
  const getKioskOperationById = generateGetterById<KioskOperation>(kioskOperations);
  const getShiftById = generateGetterById<Shift>(shifts);
  const getTenantWorkerCostById = generateGetterById<TenantWorkerCost>(tenantWorkerCosts);
  const getTenantWorkerById = generateGetterById<TenantWorker>(tenantWorkers);
  const getWorkerAttendanceById = generateGetterById<WorkerAttendance>(workerAttendances);
  const getMyEventById = generateGetterById<MyEvent>(myEvents);
  const getMyShiftById = generateGetterById<MyShift>(myShifts);

  const fetchCommitment = async (filter: {commitment_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-commitment', commitments, filter);
  };

  const fetchCommitmentsForEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-commitments-for-event', commitments, filter);
  };

  const fetchCommitmentEffort = async (filter: {commitment_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-commitment-effort', commitmentEfforts, filter);
  };

  const fetchCommitmentEffortsForEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-commitment-efforts-for-event', commitmentEfforts, filter);
  };

  const fetchCommitmentsForEventWithStatuses = async (
    filter: {event_id: string; status: any} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('event.find-commitments-for-event-with-statuses', commitments, filter);
  };

  const fetchCommitmentsForShift = async (filter: {shift_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-commitments-for-shift', commitments, filter);
  };

  const fetchCoWorker = async (filter: {co_worker_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-co-worker', coWorkers, filter);
  };

  const fetchCoWorkersForEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-co-workers-for-event', coWorkers, filter);
  };

  const fetchCoWorkersForShift = async (filter: {shift_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-co-workers-for-shift', coWorkers, filter);
  };

  const fetchTenantWorker = async (filter: {tenant_worker_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-tenant-worker', tenantWorkers, filter);
  };

  const fetchTenantWorkersForEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-tenant-workers-for-event', tenantWorkers, filter);
  };

  const fetchWorkerAttendance = async (
    filter: {worker_attendance_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('event.find-worker-attendance', workerAttendances, filter);
  };

  const fetchWorkerAttendanceForWorkday = async (
    filter: {event_id: string; workday: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('event.find-worker-attendance-for-workday', workerAttendances, filter);
  };

  const fetchCashRegisterCard = async (
    filter: {cash_register_card_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('event.find-cash-register-card', cashRegisterCards, filter);
  };

  const fetchCashRegisterCardsForWorkday = async (
    filter: {event_id: string; workday: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('event.find-cash-register-cards-for-workday', cashRegisterCards, filter);
  };

  const fetchCashRegisterCardsForWorker = async (
    filter: {event_id: string; workday: string; worker: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('event.find-cash-register-cards-for-worker', cashRegisterCards, filter);
  };

  const fetchEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-event', events, filter);
  };

  const fetchMyEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-my-event', myEvents, filter);
  };

  const fetchEvents = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('event.find-events', events, filter);
  };

  const fetchEventsForYear = async (filter: {year: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-events-for-year', events, filter);
  };

  const fetchShift = async (filter: {shift_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-shift', shifts, filter);
  };

  const fetchShiftsForEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-shifts-for-event', shifts, filter);
  };

  const fetchMyShiftsForEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-my-shifts-for-event', myShifts, filter);
  };

  const fetchKioskOperations = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-kiosk-operations', kioskOperations, filter);
  };

  const fetchKioskOperation = async (filter: {kiosk_operation_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('event.find-kiosk-operation', kioskOperations, filter);
  };

  const initEvent = async (data: {
    event_id?: string;
    event_category_id: string;
    location_id: string;
    event_name: string;
    event_schedule: object;
  }): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.init-event', data);
  };

  const cancelEvent = async (data: {event_id?: string}): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.cancel-event', data);
  };

  const setEventName = async (data: {event_id?: string; event_name: string}): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.set-event-name', data);
  };

  const setEventDescription = async (data: {event_id?: string; event_description: string[]}): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.set-event-description', data);
  };

  const setEventFeatures = async (data: {event_id?: string; event_features: object}): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.set-event-features', data);
  };

  const requireKiosk = async (data: {event_id?: string; kiosk_id: string}): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.require-kiosk', data);
  };

  const openKiosk = async (data: {kiosk_operation_id?: string}): Promise<void> => {
    data['kiosk_operation_id'] ??= generateAggregateId();
    await command('event.open-kiosk', data);
  };

  const closeKiosk = async (data: {kiosk_operation_id?: string}): Promise<void> => {
    data['kiosk_operation_id'] ??= generateAggregateId();
    await command('event.close-kiosk', data);
  };

  const requireShiftInKiosk = async (data: {
    kiosk_operation_id?: string;
    shift_id: string;
    required_employees: number;
  }): Promise<void> => {
    data['kiosk_operation_id'] ??= generateAggregateId();
    await command('event.require-shift-in-kiosk', data);
  };

  const dismissShiftFromKiosk = async (data: {kiosk_operation_id?: string; shift_id: string}): Promise<void> => {
    data['kiosk_operation_id'] ??= generateAggregateId();
    await command('event.dismiss-shift-from-kiosk', data);
  };

  const createShift = async (data: {
    shift_id?: string;
    event_id: string;
    task_id: string;
    shift_period: object;
  }): Promise<void> => {
    data['shift_id'] ??= generateAggregateId();
    await command('event.create-shift', data);
  };

  const updateShiftPeriod = async (data: {shift_id?: string; shift_period: object}): Promise<void> => {
    data['shift_id'] ??= generateAggregateId();
    await command('event.update-shift-period', data);
  };

  const cancelShift = async (data: {shift_id?: string}): Promise<void> => {
    data['shift_id'] ??= generateAggregateId();
    await command('event.cancel-shift', data);
  };

  const requestEmployee = async (data: {shift_id?: string; employee_id: string}): Promise<void> => {
    data['shift_id'] ??= generateAggregateId();
    await command('event.request-employee', data);
  };

  const appointEmployee = async (data: {shift_id?: string; employee_id: string}): Promise<void> => {
    data['shift_id'] ??= generateAggregateId();
    await command('event.appoint-employee', data);
  };

  const createCommitment = async (data: {
    commitment_id?: string;
    shift_id: string;
    employee_id: string;
  }): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.create-commitment', data);
  };

  const requestShift = async (data: {commitment_id?: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.request-shift', data);
  };

  const acceptShift = async (data: {commitment_id?: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.accept-shift', data);
  };

  const declineShift = async (data: {commitment_id?: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.decline-shift', data);
  };

  const dismissShift = async (data: {commitment_id?: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.dismiss-shift', data);
  };

  const appointShift = async (data: {commitment_id?: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.appoint-shift', data);
  };

  const confirmShift = async (data: {commitment_id?: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.confirm-shift', data);
  };

  const setCommitmentKiosk = async (data: {commitment_id?: string; kiosk_id: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.set-commitment-kiosk', data);
  };

  const removeCommitmentKiosk = async (data: {commitment_id?: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.remove-commitment-kiosk', data);
  };

  const replaceShift = async (data: {commitment_id?: string; shift_id: string}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.replace-shift', data);
  };

  const setCommitmentEffort = async (data: {commitment_id?: string; commitment_effort: object}): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.set-commitment-effort', data);
  };

  const setCommitmentTravelExpenses = async (data: {
    commitment_id?: string;
    commitment_travel_expenses: number;
  }): Promise<void> => {
    data['commitment_id'] ??= generateAggregateId();
    await command('event.set-commitment-travel-expenses', data);
  };

  const setDefaultCostObjectForEvent = async (data: {event_id?: string; cost_object_id: string}): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.set-default-cost-object-for-event', data);
  };

  const setDefaultCostCenterForEvent = async (data: {event_id?: string; cost_center_id: string}): Promise<void> => {
    data['event_id'] ??= generateAggregateId();
    await command('event.set-default-cost-center-for-event', data);
  };

  const requestCoWorker = async (data: {
    co_worker_id?: string;
    staffing_agency_id: string;
    shift_id: string;
  }): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.request-co-worker', data);
  };

  const dismissCoWorker = async (data: {co_worker_id?: string}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.dismiss-co-worker', data);
  };

  const replaceCoWorkerShift = async (data: {co_worker_id?: string; shift_id: string}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.replace-co-worker-shift', data);
  };

  const setCoWorkerName = async (data: {co_worker_id?: string; co_worker_name: object}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.set-co-worker-name', data);
  };

  const setCoWorkerBirthday = async (data: {co_worker_id?: string; co_worker_birthday: any}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.set-co-worker-birthday', data);
  };

  const setCoWorkerBirthplace = async (data: {co_worker_id?: string; co_worker_birthplace: string}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.set-co-worker-birthplace', data);
  };

  const setCoWorkerRemarks = async (data: {co_worker_id?: string; co_worker_remarks: string}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.set-co-worker-remarks', data);
  };

  const setCoWorkerKiosk = async (data: {co_worker_id?: string; kiosk_id: string}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.set-co-worker-kiosk', data);
  };

  const removeCoWorkerKiosk = async (data: {co_worker_id?: string}): Promise<void> => {
    data['co_worker_id'] ??= generateAggregateId();
    await command('event.remove-co-worker-kiosk', data);
  };

  const setKioskTenant = async (data: {kiosk_operation_id?: string; kiosk_tenant_id: string}): Promise<void> => {
    data['kiosk_operation_id'] ??= generateAggregateId();
    await command('event.set-kiosk-tenant', data);
  };

  const removeKioskTenant = async (data: {kiosk_operation_id?: string}): Promise<void> => {
    data['kiosk_operation_id'] ??= generateAggregateId();
    await command('event.remove-kiosk-tenant', data);
  };

  const setTenantWorkerName = async (data: {tenant_worker_id?: string; tenant_worker_name: object}): Promise<void> => {
    data['tenant_worker_id'] ??= generateAggregateId();
    await command('event.set-tenant-worker-name', data);
  };

  const setTenantWorkerBirthday = async (data: {
    tenant_worker_id?: string;
    tenant_worker_birthday: any;
  }): Promise<void> => {
    data['tenant_worker_id'] ??= generateAggregateId();
    await command('event.set-tenant-worker-birthday', data);
  };

  const setTenantWorkerBirthplace = async (data: {
    tenant_worker_id?: string;
    tenant_worker_birthplace: string;
  }): Promise<void> => {
    data['tenant_worker_id'] ??= generateAggregateId();
    await command('event.set-tenant-worker-birthplace', data);
  };

  const setTenantWorkerAddress = async (data: {
    tenant_worker_id?: string;
    tenant_worker_address: object;
  }): Promise<void> => {
    data['tenant_worker_id'] ??= generateAggregateId();
    await command('event.set-tenant-worker-address', data);
  };

  const setTenantWorkerIdNumber = async (data: {
    tenant_worker_id?: string;
    tenant_worker_id_number: string;
  }): Promise<void> => {
    data['tenant_worker_id'] ??= generateAggregateId();
    await command('event.set-tenant-worker-id-number', data);
  };

  const setTenantWorkerClothesSize = async (data: {
    tenant_worker_id?: string;
    tenant_worker_clothes_size: string;
  }): Promise<void> => {
    data['tenant_worker_id'] ??= generateAggregateId();
    await command('event.set-tenant-worker-clothes-size', data);
  };

  const setEventTaskTargetFigure = async (data: {
    event_task_target_id?: string;
    event_id: string;
    task_id: string;
    task_target_figure: number;
  }): Promise<void> => {
    data['event_task_target_id'] ??= generateAggregateId();
    await command('event.set-event-task-target-figure', data);
  };

  const setShiftTargetFigure = async (data: {shift_id?: string; shift_target_figure: number}): Promise<void> => {
    data['shift_id'] ??= generateAggregateId();
    await command('event.set-shift-target-figure', data);
  };

  const initWorkerAttendance = async (data: {
    worker_attendance_id?: string;
    event_id: string;
    workday: any;
    worker: string;
  }): Promise<void> => {
    data['worker_attendance_id'] ??= generateAggregateId();
    await command('event.init-worker-attendance', data);
  };

  const checkInWorker = async (data: {worker_attendance_id?: string; check_in_time: any}): Promise<void> => {
    data['worker_attendance_id'] ??= generateAggregateId();
    await command('event.check-in-worker', data);
  };

  const checkOutWorker = async (data: {worker_attendance_id?: string; check_out_time: any}): Promise<void> => {
    data['worker_attendance_id'] ??= generateAggregateId();
    await command('event.check-out-worker', data);
  };

  const setWorkerAttendanceNotes = async (data: {
    worker_attendance_id?: string;
    attendance_notes: string;
  }): Promise<void> => {
    data['worker_attendance_id'] ??= generateAggregateId();
    await command('event.set-worker-attendance-notes', data);
  };

  const assignWorkerAttendanceAccreditation = async (data: {
    worker_attendance_id?: string;
    attendance_accreditation: string;
  }): Promise<void> => {
    data['worker_attendance_id'] ??= generateAggregateId();
    await command('event.assign-worker-attendance-accreditation', data);
  };

  const initCashRegisterCardIssuance = async (data: {
    cash_register_card_issuance_id?: string;
    event_id: string;
    workday: any;
    cash_register_card_number: string;
  }): Promise<void> => {
    data['cash_register_card_issuance_id'] ??= generateAggregateId();
    await command('event.init-cash-register-card-issuance', data);
  };

  const issueCashRegisterCard = async (data: {
    cash_register_card_issuance_id?: string;
    worker: string;
  }): Promise<void> => {
    data['cash_register_card_issuance_id'] ??= generateAggregateId();
    await command('event.issue-cash-register-card', data);
  };

  const setCashRegisterCardReceipts = async (data: {
    cash_register_card_issuance_id?: string;
    cash_register_card_receipts: object;
  }): Promise<void> => {
    data['cash_register_card_issuance_id'] ??= generateAggregateId();
    await command('event.set-cash-register-card-receipts', data);
  };

  const regainCashRegisterCard = async (data: {cash_register_card_issuance_id?: string}): Promise<void> => {
    data['cash_register_card_issuance_id'] ??= generateAggregateId();
    await command('event.regain-cash-register-card', data);
  };

  return {
    cashRegisterCards,
    coWorkerCosts,
    employees,
    coWorkers,
    commitmentEfforts,
    commitmentStatusUpdates,
    commitments,
    employeeCommitments,
    employeeCosts,
    eventCategories,
    events,
    kioskOperations,
    shifts,
    tenantWorkerCosts,
    tenantWorkers,
    workerAttendances,
    myEvents,
    myShifts,
    getCashRegisterCardById,
    getCoWorkerCostById,
    getEmployeeById,
    getCoWorkerById,
    getCommitmentEffortById,
    getCommitmentStatusUpdateById,
    getCommitmentById,
    getEmployeeCommitmentById,
    getEmployeeCostById,
    getEventCategoryById,
    getEventById,
    getKioskOperationById,
    getShiftById,
    getTenantWorkerCostById,
    getTenantWorkerById,
    getWorkerAttendanceById,
    getMyEventById,
    getMyShiftById,
    fetchCommitment,
    fetchCommitmentsForEvent,
    fetchCommitmentEffort,
    fetchCommitmentEffortsForEvent,
    fetchCommitmentsForEventWithStatuses,
    fetchCommitmentsForShift,
    fetchCoWorker,
    fetchCoWorkersForEvent,
    fetchCoWorkersForShift,
    fetchTenantWorker,
    fetchTenantWorkersForEvent,
    fetchWorkerAttendance,
    fetchWorkerAttendanceForWorkday,
    fetchCashRegisterCard,
    fetchCashRegisterCardsForWorkday,
    fetchCashRegisterCardsForWorker,
    fetchEvent,
    fetchMyEvent,
    fetchEvents,
    fetchEventsForYear,
    fetchShift,
    fetchShiftsForEvent,
    fetchMyShiftsForEvent,
    fetchKioskOperations,
    fetchKioskOperation,
    initEvent,
    cancelEvent,
    setEventName,
    setEventDescription,
    setEventFeatures,
    requireKiosk,
    openKiosk,
    closeKiosk,
    requireShiftInKiosk,
    dismissShiftFromKiosk,
    createShift,
    updateShiftPeriod,
    cancelShift,
    requestEmployee,
    appointEmployee,
    createCommitment,
    requestShift,
    acceptShift,
    declineShift,
    dismissShift,
    appointShift,
    confirmShift,
    setCommitmentKiosk,
    removeCommitmentKiosk,
    replaceShift,
    setCommitmentEffort,
    setCommitmentTravelExpenses,
    setDefaultCostObjectForEvent,
    setDefaultCostCenterForEvent,
    requestCoWorker,
    dismissCoWorker,
    replaceCoWorkerShift,
    setCoWorkerName,
    setCoWorkerBirthday,
    setCoWorkerBirthplace,
    setCoWorkerRemarks,
    setCoWorkerKiosk,
    removeCoWorkerKiosk,
    setKioskTenant,
    removeKioskTenant,
    setTenantWorkerName,
    setTenantWorkerBirthday,
    setTenantWorkerBirthplace,
    setTenantWorkerAddress,
    setTenantWorkerIdNumber,
    setTenantWorkerClothesSize,
    setEventTaskTargetFigure,
    setShiftTargetFigure,
    initWorkerAttendance,
    checkInWorker,
    checkOutWorker,
    setWorkerAttendanceNotes,
    assignWorkerAttendanceAccreditation,
    initCashRegisterCardIssuance,
    issueCashRegisterCard,
    setCashRegisterCardReceipts,
    regainCashRegisterCard,
  };
});

export interface WorkerAttendance {
  worker_attendance_id: string;
  event_id: string;
  workday: string;
  worker: string;
  checked_in_at: string | null;
  checked_out_at: string | null;
  notes: string;
  accreditations: string[];
  history: {label: string; value: string}[];
}

export interface CashRegisterCard {
  cash_register_card_id: string;
  event_id: string;
  workday: string;
  number: string;
  worker: string | null;
  receipts: {close_out_sums?: number[]; vouchers?: {[key: string]: number}};
  regained: boolean;
}

export interface Commitment {
  commitment_id: string;
  event_id: string;
  employee_id: string;
  task_id: string;
  shift_id: string;
  shift_date: string;
  kiosk_id: string | null;
  status: string | null;
  confirmed: boolean;
  accomplished: boolean;
}

export interface CommitmentEffort {
  commitment_id: string;
  effort: {
    wage_profile_id: string;
    wage_bonus_id: string;
    paused_minutes: number;
    worked_minutes: number;
    cost_object_id: string;
    cost_center_id: string;
    notice: string;
  } | null;
  expenditures: {travel_expenses?: number};
}

export interface Shift {
  shift_id: string;
  event_id: string;
  task_id: string;
  shift_period: object;
  shift_target_figure: number | null;
  cancelled: boolean;
}

export interface ShiftPeriod {
  date: string;
  from: string;
  to: string;
}

export interface EventSchedule {
  start_date: string;
  end_date: string;
}

export interface Event {
  event_id: string;
  event_category_id: string;
  location_id: string;
  name: string;
  description: string | null;
  features: string[];
  schedule: object;
  cost_object_id: string | null;
  cost_center_id: string | null;
  task_target_figures: {[key: string]: number};
  cancelled: boolean;
}

export interface EventCategory {
  event_category_id: string;
  event_category_type: string;
  event_category_label: string;
  event_category_icon: string;
}

export interface KioskOperation {
  kiosk_operation_id: string;
  kiosk_id: string;
  event_id: string;
  tenant_id: string | null;
  shifts: {[key: string]: {shift_id: string; required_employees: number}};
  active: boolean;
}

export interface EmployeeEvent {
  event_id: string;
  event_category_id: string;
  location_id: string;
  name: string;
  schedule: object;
}

export interface EmployeeCommitment {
  commitment_id: string;
  employee_id: string;
  event_id: string;
  task_id: string;
  shift_id: string;
  shift_date: string;
  kiosk_id: string | null;
  status: string | null;
  confirmed: boolean;
}

export interface CommitmentStatusUpdate {
  commitment_id: string;
  employee_id: string;
  shift_id: string;
  has_updates: boolean;
  last_status: string | null;
  last_shift_period: ShiftPeriod | null;
}

export interface Employee {
  employee_id: string;
  full_name: string;
  full_aged_from: string | null;
  avatar: string | null;
  first_effort_date: string | null;
  effort_amount: number;
  last_efforts: {task_id: string; kiosk_id: string | null; effort_day: string}[];
  wage_profile_id: string | null;
  relationship_type: string | null;
  employment_ends_at: string | null;
  tags: string[];
  has_conflicts: boolean;
}

export interface CoWorker {
  co_worker_id: string;
  staffing_agency_id: string;
  shift_id: string;
  event_id: string;
  task_id: string;
  kiosk_id: string | null;
  name: {first_name: string; last_name: string} | null;
  birthday: string | null;
  full_aged_from: string | null;
  birthplace: string | null;
  remarks: string | null;
  checked_in_at: string | null;
  checked_out_at: string | null;
}

export interface TenantWorker {
  tenant_worker_id: string;
  kiosk_operation_id: string;
  shift_id: string;
  event_id: string;
  kiosk_id: string;
  kiosk_tenant_id: string;
  name: {first_name: string; last_name: string} | null;
  birthday: string | null;
  birthplace: string | null;
  address: {
    street_and_house_number: string;
    address_addition: string;
    postal_routing_number: string;
    town: string;
    country: string;
  } | null;
  id_number: string | null;
  clothes_size: string | null;
  checked_in_at: string | null;
  checked_out_at: string | null;
}

export interface EmployeeCost {
  employee_cost_id: string;
  employee_id: string;
  task_scope_id: string;
  task_id: string;
  kiosk_id: string | null;
  worked_minutes: number;
  expenses: number;
  costs_in_eurocents: number;
}

export interface CoWorkerCost {
  co_worker_cost_id: string;
  co_worker_id: string;
  staffing_agency_id: string;
  task_scope_id: string;
  task_id: string;
  kiosk_id: string | null;
  worked_minutes: number;
  costs_in_eurocents: number;
}

export interface TenantWorkerCost {
  tenant_worker_cost_id: string;
  tenant_worker_id: string;
  kiosk_tenant_id: string;
  task_scope_id: string;
  task_id: string;
  kiosk_id: string | null;
  worked_minutes: number;
}

export interface MyEvent {
  event_id: string;
  event_category_id: string;
  location_id: string;
  name: string;
  description: string | null;
  schedule: object;
}

export interface MyShift {
  shift_id: string;
  event_id: string;
  task_id: string;
  shift_period: ShiftPeriod;
}

export interface CashRegisterCards {
  [key: string]: CashRegisterCard;
}

export interface CoWorkerCosts {
  [key: string]: CoWorkerCost;
}

export interface Employees {
  [key: string]: Employee;
}

export interface CoWorkers {
  [key: string]: CoWorker;
}

export interface CommitmentEfforts {
  [key: string]: CommitmentEffort;
}

export interface CommitmentStatusUpdates {
  [key: string]: CommitmentStatusUpdate;
}

export interface Commitments {
  [key: string]: Commitment;
}

export interface EmployeeCommitments {
  [key: string]: EmployeeCommitment;
}

export interface EmployeeCosts {
  [key: string]: EmployeeCost;
}

export interface EventCategories {
  [key: string]: EventCategory;
}

export interface Events {
  [key: string]: Event;
}

export interface KioskOperations {
  [key: string]: KioskOperation;
}

export interface Shifts {
  [key: string]: Shift;
}

export interface TenantWorkerCosts {
  [key: string]: TenantWorkerCost;
}

export interface TenantWorkers {
  [key: string]: TenantWorker;
}

export interface WorkerAttendances {
  [key: string]: WorkerAttendance;
}

export interface MyEvents {
  [key: string]: MyEvent;
}

export interface MyShifts {
  [key: string]: MyShift;
}
